import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
// import Select from 'react-select'
// import isEmpty from 'lodash/isEmpty'
// import queryString from 'querystring'
// import { useHistory } from 'react-router-dom'
import { useGetRequest, useInfiniteScroll, useLoad, usePostRequest } from '../hooks/request'
import Layout from '../components/Layout/Layout'
import { ALL_SEND_MESSAGE, CLIENT_LIST, FILE_UPLOAD, SEND_MESSAGE_SELECT } from '../urls'
import { useModal } from '../hooks/modal'
import Table from '../components/common/Table'
import BorrowItem from '../components/BorrowItem'
import ClientCreate from '../components/ClientCreate'
import Button from '../components/common/Button'
// import EmployeeTabs from '../components/EmployeeTabs'
import CustomersFilter from '../components/CustomersFilter'
import Loader from '../components/common/Loader'
import { useMessage } from '../hooks/message'
// import { useQueryParams } from '../hooks/queryString'

export default function Borrow() {
    const [page, setPage] = useState(1)
    // const [params] = useQueryParams()
    const clients = useInfiniteScroll({
        url: CLIENT_LIST,
    })
    const productList = clients.response ? clients.response.results : []
    const [showCreateModal, setShowCreateModal] = useModal(
        <ClientCreate
            clients={clients}
            onCancel={() => setShowCreateModal()}
        />,
    )
    const update = useGetRequest({ url: '/main/parse' })
    const { request: postExcel } = usePostRequest({ url: FILE_UPLOAD })
    const handleFileUploadOnChange = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('file', file)
        const { success, error } = await postExcel({ data: formData })
        if (success) {
            clients.request()
            showMessage('Файл успешно отправлен', 'is-success')
        }
        if (error) {
            showMessage(error?.data?.detail ?? `Error: ${error?.status} ${error?.statusText}`, 'is-danger')
        }
    }
    // const history = useHistory()

    // function onSearch(query) {
    //     const search = !isEmpty(query) ? query : undefined
    //     history.push(`?${queryString.stringify({ ...params, search })}`)
    // }

    const [checkedItems, setCheckedItems] = useState([])
    const handleCheckboxChange = (itemId, isChecked) => {
        setCheckedItems((prevState) => {
            if (isChecked) {
                return [...prevState, itemId]
            }
            return prevState.filter((id) => id !== itemId)
        })
    }
    const [showMessage] = useMessage()
    const send = usePostRequest({ url: SEND_MESSAGE_SELECT })

    async function onSend() {
        const { success } = await send.request({ data: { client_ids: checkedItems } })
        if (success) {
            showMessage('Сизнинг sms хабарингиз муваффақиятли юборилди', 'is-success')
            setCheckedItems([])
            clients.request()
        }
    }

    const { response: totalBorrowRes } = useLoad({
        url: '/main/total_borrow',
    })
    const total = totalBorrowRes?.totalAmount || 0
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }

    const sendMessage = useGetRequest({ url: ALL_SEND_MESSAGE })

    async function onSendToAll() {
        const { success } = await sendMessage.request()
        if (success) {
            showMessage('Сизнинг sms хабарингиз  муваффақиятли юборилди', 'is-success')
        }
    }
    return (
        <Layout>
            <main style={{ gap: '4rem' }} className="is-flex is-justify-content-space-between mb-2">
                <div className={cn(css(styles.searchBar))}>
                    {/* <Select /> */}
                    <Button
                        onClick={update.request}
                        loading={update.loading}
                        text="Обновить"
                        className="is-link is-outlined"
                        icon="refresh-outline"
                    />
                    <Button
                        onClick={onSendToAll}
                        text="Всем SMS"
                        className="is-link is-outlined"
                        icon="refresh-outline"
                    />
                    <Button
                        loading={send.loading}
                        onClick={onSend}
                        text="Выберите отправить"
                        icon="paper-plane"
                        className="is-link is-outlined mb-2 mt-2"
                    />
                    <label style={{ position: 'relative' }}>
                        <Button
                            text="Отправить Excel"
                            className="is-link is-outlined mr-2"
                            icon="document-outline"
                        />
                        <input
                            value=""
                            onChange={handleFileUploadOnChange}
                            type="file"
                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }}
                        />
                    </label>

                </div>

                <CustomersFilter className={cn(css(styles.search), 'mt-2')} />

                <Button
                    onClick={showCreateModal}
                    text="Добавить"
                    icon="add-outline"
                    className="is-link is-outlined mb-2"
                />
            </main>

            <div className="pt-3" style={{ textAlign: 'center', display: 'flex' }}>
                <span className="tag is-link is-light is-medium">
                    <h3 className="is-size-5 mr-4">Общая Сумма:</h3>
                    <p className="is-size-5" style={{ fontWeight: '600' }}>{numberWithCommas(total)}</p>
                </span>
            </div>

            <br />

            <div className={css(styles.table)}>
                <div className={css(styles.tableItem)}>
                    <Table
                        // loading={clients.loading}
                        items={productList}
                        columns={{
                            sadasdas: '',
                            name: 'Имя',
                            price: 'Сумма',
                            address: 'Номер телефона',
                            actisadons: 'Выберите',
                            actidssadons: 'Последней отправки',
                            action: '',
                        }}
                        totalCount={clients.response ? clients.response.count : 0}
                        pageSize={15}
                        activePage={page}
                        onPageChange={setPage}
                        renderItem={(item, index) => (
                            <BorrowItem
                                onSendSelect={onSend}
                                index={index}
                                onCheckboxChange={handleCheckboxChange}
                                clients={clients}
                                products={clients}
                                key={item.id}
                                item={item}
                            />
                        )} />
                </div>
                <Loader show={clients.loading} center large />

                <div ref={clients.ref} className="has-text-grey-light is-italic has-text-centered">
                    {!clients.hasMore && !clients.loading && clients.length !== 0 ? 'Загрузили все клиенты' : ''}
                </div>
            </div>
        </Layout>
    )
}
const styles = StyleSheet.create({
    column: {
        marginRight: '10px',
    },
    searchBar: {
        '@media (min-width: 900px)': {
            height: '0',
        },
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        flexWrap: 'wrap',
    },

    table: {
        '@media (max-width: 500px)': {
            overflowX: 'scroll',
        },
    },
    tableItem: {
        '@media (max-width: 500px)': {
            width: 800,
        },
    },
    br: {
        '@media (max-width: 500px)': {
            width: 800,
        },
    },
})
