import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { Form, Formik } from 'formik'
import React from 'react'
import { NavLink, Redirect, useHistory } from 'react-router-dom'
import Button from '../components/common/Button'
import Input from '../components/common/Input'
import ServerError from '../components/common/ServerError'
import { usePostRequest } from '../hooks/request'
import { SIGN_IN } from '../urls'
import { email, required, validator } from '../utils/validators'
import Password from '../components/Password'
import { isAuthenticated, signin } from '../utils/auth'
import LayoutAuth from '../components/LayoutAuth'

export default function Login() {
    const history = useHistory()
    const signIn = usePostRequest({ url: SIGN_IN, headers: {} })

    if (isAuthenticated()) {
        return <Redirect to="/borrow" />
    }

    async function onSubmit(data) {
        const { response, success } = await signIn.request({ data })

        if (success) {
            signin(response, history)
        }
    }

    return (
        <LayoutAuth sidebar={(
            <div>
                <h2 className="is-size-4 has-text-weight-bold">Добро пожаловать!</h2>
                <p className="is-size-5">Эта CRM-система разработана
                    <a className="is-uppercase ml-2 has-text-white"
                        href="https://craftsoft.uz/">craftsoft.uz
                    </a>
                </p>
                <p className="is-size-5">
                    Номер телефона:
                    <a className="has-text-white ml-2" href="tel:+998994204488">+998
                        (99) 420 44-88
                    </a>
                </p>
                <br />
            </div>
        )}>
            <h2 className={cn('is-size-4 has-text-weight-bold', css(styles.hasTextPrimary))}>Войдите в систему</h2>
            <br />

            <Formik onSubmit={onSubmit} initialValues={{ email: '', password: '' }}>
                <Form>
                    <ServerError error={signIn.error} />
                    <Input name="email" icon="ion-md-mail" validate={validator(required, email)} placeholder="Email" />
                    <Password name="password" icon="ion-md-key" validate={required} placeholder="Password" />

                    <div className="field">
                        <div className="control">
                            <Button
                                loading={signIn.loading}
                                className="button is-link is-medium is-fullwidth"
                                text="Войти"
                                type="submit" />
                        </div>
                    </div>
                    <div className={css(styles.onlyMobile)}>
                        <NavLink to="/sign-up">Зарегистрироваться</NavLink>
                        <br />
                        <br />
                    </div>

                    <div className="mt-5 forgot-password">
                        <div className="has-text-centered">
                            <NavLink to="/reset-link" className={css(styles.forgotPassword)}>Забыли пароль?</NavLink>
                        </div>
                    </div>
                </Form>
            </Formik>
        </LayoutAuth>
    )
}

const styles = StyleSheet.create({
    container: {
        padding: '1.25rem',
    },
    img: {
        width: '10rem',
    },
    roundedButton: {
        borderRadius: '50%',
    },
    input: {
        color: '#4a4a4a',
    },
    onlyMobile: {
        textAlign: 'center',
        '@media (min-width: 769px)': {
            display: 'none',
        },
    },
    forgotPassword: {
        color: '#999',
        fontSize: '.9rem',
        ':hover': {
            color: '#0062ff',
        },
    },
})
