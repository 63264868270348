export function auth() {
    return {
        headers: {
            Authorization: localStorage.token ? `Token ${localStorage.token}` : undefined,
        },
    }
}

let permissions

function getPermissions() {
    const user = JSON.parse(localStorage.getItem('user')) || {}

    switch (user.userType) {
    case 'ADMIN':
        return ['READ', 'WRITE', 'DELETE']
    case 'admin':
        return ['READ', 'WRITE', 'DELETE']
    case 'customer':
        return ['READ_CLIENT']
    default:
        return ['READ', 'WRITE', 'DELETE']
    }
}

export function checkPermission(requiredPermission) {
    if (!permissions) {
        permissions = getPermissions()
    }

    return permissions.includes(requiredPermission)
}

export function clearPermissions() {
    permissions = undefined
}
export function signin({ user, token, permissions: userPermissions }, history) {
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('token', token)
    localStorage.setItem('language', 'ru')
    localStorage.setItem('permissions', JSON.stringify(userPermissions))

    if (user.userType === 'admin') {
        history.push('/products')
    } else if (user.userType === 'customer') {
        history.push('/client')
    } else {
        history.push('/')
    }
}

export function signOut(history) {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    localStorage.removeItem('permissions')
    clearPermissions()
    history.push('/')
}

export function isAuthenticated() {
    return localStorage.getItem('user')
        && localStorage.getItem('token')
        && localStorage.getItem('permissions')
}
