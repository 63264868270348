import { Form, Formik } from 'formik'
import React from 'react'
import { required } from '../../utils/validators'
import Input from '../common/Input'
import Button from '../common/Button'

export default function UserForm({ onSubmit, loading }) {
    return (
        <Formik initialValues={{
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        }} onSubmit={onSubmit}>
            <Form>
                <Input
                    name="oldPassword"
                    type="password"
                    label="Старый пароль"
                    placeholder="Введите старый пароль"
                    disabled={loading}
                    validate={required} />

                <Input
                    name="newPassword"
                    type="password"
                    label="Новый пароль"
                    placeholder="Введите новый пароль"
                    disabled={loading}
                    validate={required} />

                <Input
                    name="confirmPassword"
                    type="password"
                    label="Новый пароль ещё раз"
                    placeholder="Введите новый пароль ещё раз"
                    disabled={loading}
                    validate={required} />

                <Button
                    loading={loading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    disabled={loading}
                    className="is-success" /> &nbsp;
            </Form>
        </Formik>
    )
}
