import React from 'react'
import { usePostRequest } from '../hooks/request'
import { USER_LIST } from '../urls'
import { useMessage } from '../hooks/message'
import UserForm from './settings/UserForm'

export default function UserCreate({
    reload,
    onCancel,
    userList,
    territory,
}) {
    const market = usePostRequest({ url: USER_LIST })
    const [showMessage] = useMessage()

    async function onSubmit(data) {
        const { success } = await market.request({ data })

        if (success) {
            showMessage('Успешно сохранен', 'is-success')
            reload.request()
            onCancel()
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered"><b>Добавить магазины</b></h1>

            <UserForm
                onCancel={onCancel}
                userList={userList}
                onSubmit={onSubmit}
                territory={territory} />
        </div>
    )
}
