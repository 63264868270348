import React from 'react'
import Button from './common/Button'
import { BORROW_DETAIL, CHECK_PASSWORD } from '../urls'
import { useDeleteRequest, usePostRequest } from '../hooks/request'
import BorrowUpdate from './BorrowUpdate'
import { getDateOtherFormat } from '../utils/date'
import { format } from '../utils/number'
import { useCustomModal } from '../hooks/customModal'

export default function ClientBorrowItem({
    reload,
    item,
    borrows,
    index,
}) {
    const clientBorrowRemove = useDeleteRequest({ url: BORROW_DETAIL.replace('{id}', item.id) })
    const checkPassword = usePostRequest({ url: CHECK_PASSWORD })

    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            const password = global.prompt('Enter password to proceed:', '')
            try {
                const { success } = await checkPassword.request({ data: { password } })
                if (success) {
                    await clientBorrowRemove.request()
                    await borrows.request()
                } else {
                    global.alert('Incorrect password. Deletion aborted.')
                }
            } catch (error) {
                global.alert('An error occurred. Deletion aborted.')
            }
        }
    }

    const [showUpdateModal, hideUpdateModal] = useCustomModal(
        <BorrowUpdate
            borrows={borrows}
            reload={reload}
            item={item}
            hideModal={() => hideUpdateModal()}
        />,
    )
    const currentDate = new Date()
    return (
        <tr className={`${getDateOtherFormat(currentDate) > item.term ? 'has-text-danger' : 'has-text-black'}`}
            key={item.id}>
            <td>{index + 1}</td>
            <td>{format(item.amount)}</td>
            <td />
            <td />
            <td style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    onClick={showUpdateModal}
                    className="mr-2 is-success"
                    icon="pencil-outline"
                />
                <Button
                    onClick={onDelete}
                    className="is-danger mr-2"
                    icon="trash"
                />
            </td>

        </tr>

    )
}
