import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { NavLink, useRouteMatch } from 'react-router-dom'
import React, { useState } from 'react'
import { getEnv } from '../../utils/environment'
import AccountPanel from '../AccountPanel'

export default function Layout({ children, padding = true, className }) {
    const { path } = useRouteMatch()
    const [menu, setMenu] = useState(false)

    return (
        <div>
            <div className={css(
                styles.sidebar,
                menu ? styles.desktopSidebar : styles.mobileSidebar,
                getEnv() === 'development' ? styles.developmentSidebar : null,
                getEnv() === 'staging' ? styles.stagingSidebar : null,
            )}>
                <div style={{ marginTop: 150 }}>
                    <AccountPanel />
                </div>
                <i onClick={() => setMenu(false)} className={cn(
                    'icon ion-md-arrow-back is-size-4',
                    css(styles.hiderIcon),
                )} />

                <p className={css(styles.menuHeading)}>
                    Меню
                </p>

                <ul className={css(styles.menu)}>

                    <li onClick={() => setMenu(false)}>
                        <NavLink to="/borrow" className={css(
                            styles.link,
                            path.startsWith('/borrow') ? styles.active : null,
                        )}>
                            <i className={cn('icon ion-md-list', css(styles.icon))} />
                            Долги
                        </NavLink>
                    </li>

                    <li onClick={() => setMenu(false)}>
                        <NavLink to="/settings/user" className={cn(css(styles.link),
                            { [css(styles.active)]: path.startsWith('/settings/user') })}>
                            <i className={cn('icon ion-md-settings', css(styles.icon))} />
                            Настройки
                        </NavLink>
                    </li>
                </ul>
            </div>

            <div className={cn(css(styles.container))}>
                <div className={cn(className, css(styles.content), { [css(styles.padding)]: padding })}>
                    <div>
                        <i onClick={() => setMenu(true)} className={cn(
                            'icon ion-md-menu is-size-3',
                            css(styles.showerIcon),
                        )} />
                    </div>
                    {children}
                </div>
            </div>

            <div className={css(menu ? styles.background : null)} onClick={() => setMenu(false)} />
        </div>
    )
}

const styles = StyleSheet.create({
    sidebar: {
        minHeight: '100%',
        position: 'fixed',
        background: '#1f2668',
        width: '14.1rem',
        margin: '0',
        color: 'white',
        padding: '0 1.2rem 0 2.8rem',
        zIndex: '5',
        '::-webkit-scrollbar': {
            display: 'none',
        },
        '@media (max-height: 525px)': {
            height: 'calc(100vh - 100%)',
            overflowX: 'scroll',
        },
    },
    developmentSidebar: {
        // background: '#004d40',
    },
    stagingSidebar: {
        background: '#000000',
    },
    desktopSidebar: {
        '@media (max-width: 769px)': {
            transform: 'translateX(0)',
            transition: 'all 300ms',
        },
    },
    mobileSidebar: {
        '@media (max-width: 769px)': {
            transform: 'translateX(-14rem)',
            transition: 'all 300ms',
        },
    },
    icon: {
        margin: '0 0.3rem 0.9rem 0',
    },
    link: {
        color: 'RGBA(255, 255, 255, .6)',
    },
    logo: {
        margin: '3.4rem 0.4rem',
        width: '8rem',
    },
    menuHeading: {
        color: 'RGBA(255,255,255, .6)',
        margin: '0 0 1.5rem 0',
    },
    menu: {
        margin: '0 0 3.1rem 0',
    },
    padding: {
        padding: '1.8rem',
    },
    container: {
        margin: '0 0 0 14rem',
        '@media (max-width: 769px)': {
            margin: '0',
        },
    },
    active: {
        fontWeight: 'bolder',
        color: 'white',
    },
    content: {
        minHeight: '100vh',
    },
    showerIcon: {
        '@media (min-width: 769px)': {
            display: 'none',
        },
    },
    hiderIcon: {
        transform: 'translate(9.6rem, -15rem)',
        color: 'white',
        '@media (min-width: 769px)': {
            display: 'none',
        },
    },
    background: {
        '@media (max-width: 769px)': {
            bottom: 0,
            left: 0,
            position: 'fixed',
            right: 0,
            top: 0,
            background: 'RGBA(0, 0, 0, .5)',
            zIndex: 4,
        },
    },
})
