import React, { useState } from 'react'
import cn from 'classnames'
import Button from './common/Button'
import { CLIENT_DETAIL, SEND_MESSAGE } from '../urls'
import { useDeleteRequest, useGetRequest } from '../hooks/request'
import { useModal } from '../hooks/modal'
import { getDateOtherFormat, timeAgo } from '../utils/date'
import { format } from '../utils/number'
import ClientBorrowList from './ClientBorrowList'
import { useMessage } from '../hooks/message'
import ClientUpdate from './ClientUpdate'

export default function BorrowItem({
    item,
    clients,
    index,
    onCheckboxChange,
}) {
    const [isChecked, setIsChecked] = useState(false)
    const sendMessage = useGetRequest({ url: SEND_MESSAGE.replace('{clientId}', item.id) })
    const [showMessage] = useMessage()
    const productRemove = useDeleteRequest({ url: CLIENT_DETAIL.replace('{id}', item.id) })

    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await productRemove.request()
            clients.request()
        }
    }

    async function onSend() {
        const { success } = await sendMessage.request()
        if (success) {
            showMessage(`Сизнинг sms хабарингиз ${item.name} га муваффақиятли юборилди`, 'is-success')
        }
    }

    const [showUpdateModal, hideUpdateModal] = useModal(
        <ClientUpdate
            clients={clients}
            item={item}
            onCancel={() => hideUpdateModal()}
        />,
    )

    const [showClientBorrowList, hideClientBorrowList] = useModal(
        <ClientBorrowList
            clients={clients}
            client={item}
            clientId={item.id}
            hideModal={() => hideClientBorrowList()}
        />,
    )
    const currentDate = new Date()

    const handleCheckboxChange = () => {
        const newCheckedState = !isChecked
        setIsChecked(newCheckedState)
        onCheckboxChange(item.id, newCheckedState)
    }

    return (
        <tr className={`${getDateOtherFormat(currentDate) > item.term ? 'has-text-danger' : 'has-text-black'}`}
            key={item.id}>
            <td>{index + 1}</td>
            <td style={{ width: '20%', cursor: 'pointer' }} onClick={showClientBorrowList} className="">
                <span className={cn('', `${item.sumAmount > 0 ? 'has-text-danger' : 'has-text-black'}`)}>
                    {item.name}
                </span>
            </td>
            <td style={{ width: '20%' }} className="">{format(item.sumAmount)}</td>
            <td style={{ width: '25%' }} className="">{item.phoneNumber}</td>

            <td>
                <input
                    className="checkbox"
                    style={{
                        width: 25,
                        height: 25,
                    }}
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                />
            </td>
            <td style={{ width: '20%' }} className="">{item.lastSendDate ? timeAgo(item.lastSendDate) : ''}</td>

            <td style={{ width: '20%' }} className="has-text-right">
                <div className="is-flex is-justify-content-right">
                    <Button
                        onClick={showUpdateModal}
                        className="mr-2 is-success button is-outlined"
                        icon="pencil-outline" />
                    <Button
                        onClick={onDelete}
                        className="button is-danger mr-2 is-outlined"
                        icon="trash" />

                    <Button
                        loading={sendMessage.loading}
                        onClick={onSend}
                        className="button is-link is-outlined"
                        icon="paper-plane-outline" />
                </div>
            </td>
        </tr>

    )
}
//
// const styles = StyleSheet.create({
//     modal: {
//         width: 1000,
//     }
//     ,
// })
